import React from 'react';
import PropTypes from 'prop-types';

const Observer = (props) => {

    const rootRef = React.useRef();

    const [state, setState] = React.useState({
        observer: null
    });

    const observerHandler = React.useCallback((entries, observer) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > props.threshold) {
                // target is moving into view
                Object.assign(entry.target.style, props.viewStyle);

            } else {
                // target is moving out of view
                const movingAbove = entry.target.getBoundingClientRect().top < 0;
                Object.assign(
                    entry.target.style,
                    movingAbove ? props.aboveStyle : props.belowStyle
                );
            }
        });
    }, [props.threshold, props.viewStyle, props.aboveStyle, props.belowStyle]);

    // on mount
    React.useEffect(() => {
        const options = {
            threshold: props.threshold,
        }

        setState(st => ({
            ...st,
            observer: new IntersectionObserver(observerHandler, options),
        }));
    }, [observerHandler, props.threshold]);

    // on observer init or selector change
    React.useEffect(() => {
        if (state.observer) {

            // unobserve all previous targets
            state.observer.disconnect();

            // get target nodes from props.selector
            const nextTargets = rootRef.current.querySelectorAll(props.selector);

            // observe next targets
            nextTargets.forEach(t => {

                // set visual transitions
                if (props.transition) {
                    t.style.transition = props.transition + (typeof props.transition === 'number' ? 'ms' : '');
                }

                state.observer.observe(t);
            });
        }
    }, [props.selector, state.observer]);

    return (
        <div ref={rootRef}>
            {props.children}
        </div>
    );
}

Observer.propTypes = {
    selector: PropTypes.string,
    threshold: PropTypes.number,
    viewStyle: PropTypes.object,
    aboveStyle: PropTypes.object,
    belowStyle: PropTypes.object,
    transition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Observer.defaultProps = {
    threshold: .5,
    viewStyle: {},
    aboveStyle: {},
    belowStyle: {},
}

export default Observer;