import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useStyles from './icon.css';

const IconButton = (props) => {

    const classes = useStyles(props);

    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer" className={classes.root}>
            <Icon icon={props.icon} />
        </a>
    );
}

IconButton.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    href: PropTypes.string,
    color: PropTypes.string,
}

export default IconButton;