import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormButton from './FormButton';
import useStyles from './contact.css';
import { CircularProgress } from '@material-ui/core';

const formspreeAction = process.env.GATSBY_FORMSPREE_CONTACT_FORM_URL;

const ContactForm = (props) => {

    const [state, setState] = React.useState({
        loading: false,
        success: false,
        error: false,
    });
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formElement = e.currentTarget;
        const formData = new FormData(formElement);

        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        }).then(res => {
            formElement.reset();
            setState(prevState => ({
                ...prevState,
                loading: false,
                success: true,
                error: false,
            }));
        }).catch(err => {
            console.error(err);
            setState(prevState => ({
                ...prevState,
                loading: false,
                success: false,
                error: true,
            }));
        });

        // set loading state
        setState(prevState => ({
            ...prevState,
            loading: true,
        }));
    }

    const renderFormStatus = () => {
        if (state.loading) {
            return <CircularProgress color="primary" size={24} />
        } else if (state.error) {
            return <Box color="error.main">There was an error sending your message.</Box>;
        } else if (state.success) {
            return <Box color="primary.main">Thank you for your message!</Box>;
        } else {
            return null;
        }
    }

    const classes = useStyles(props);

    return (
        <form
            name="contact"
            className={classes.root}
            method="post"
            onSubmit={handleSubmit}
            data-netlify="true"
            netlify-honeypot="bot-field"
        >
            <input type="hidden" name="bot-field" />
            <Grid container spacing={4} className={classes.margin}>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="contact-name"
                        label="Name"
                        name="Name"
                        type="text"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="contact-email"
                        label="Email"
                        name="Email"
                        type="email"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="contact-message"
                        label="Message"
                        name="Message"
                        type="text"
                        multiline
                        rows={1}
                        rowsMax={8}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <div className={classes.formActions}>
                <FormButton disabled={state.loading}>
                    {state.loading ? "Sending..." : "Send"}
                </FormButton>
                <div className={classes.formStatus}>
                    {renderFormStatus()}
                </div>
            </div>
        </form>
    );
}

export default ContactForm;