import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
  root: {
    display: "inline-block",
    height: "3rem",
    width: "3rem",
    padding: ".5rem",
    fontSize: "2rem",
    lineHeight: "2rem",
    cursor: "pointer",
    color: props =>
      theme.palette.hasOwnProperty(props.color)
        ? theme.palette[props.color].main
        : theme.palette.text.primary,

    "&:not(:first-of-type)": {
      marginLeft: "1rem",
    },

    "&:hover": {
      color: props =>
        theme.palette.hasOwnProperty(props.color)
          ? theme.palette[props.color].dark
          : theme.palette.primary.main,
    },
  },
}))