import { makeStyles } from "@material-ui/core/styles"
import { getMuiThemeColor } from 'components/utility/data';

export default makeStyles(theme => ({
    root: props => {

        const spacing = theme.spacing(props.spacing || 2);

        return {
            margin: `${spacing}px auto ${spacing}px`,
            maxWidth: props.width || 200,
        }
    },
    divider: {
        backgroundColor: props => props.color ? getMuiThemeColor(theme, props.color) : theme.palette.text.primary,
    }
}));