import React from 'react';
import { graphql, navigate, Link } from 'gatsby';
import { Box, Container, Hidden } from '@material-ui/core';
import Layout from 'components/layout/main';
import Section from 'components/section/section';
import SectionLink from 'components/section/link';
import SEO from 'components/seo';
import Carousel from 'components/carousel/carousel';
import Row from 'components/base/row';
import SubscribeForm from 'components/forms/SubscribeForm';
import ContactForm from 'components/forms/contact';
import IconButton from 'components/buttons/icon';
import Observer from 'components/observer/observer';
import theme from 'components/theme';
import { useLocalStorage } from 'components/utility/session';

const SectionObserver = ({ children, id, style }) => (
  <div id={id}>
    <div data-observe style={style}>
      {children}
    </div>
  </div>
);

const IndexPage = (props) => {

  const { data } = props;

  // const [viewedFW2021, setViewedFW2021] = useLocalStorage('fw2021_campaign');

  // redirect to shop page with FW2021 Campaign view if not seen yet by browser
  // if (!viewedFW2021 && typeof window !== 'undefined') {
  //   navigate('/shop');
  // }

  // Navigate to page element if window location has an "action" query param
  React.useEffect(() => {
    const query = {};
    window.location.search.replace(/^\?/, '').split('&').forEach(param => {
      const kv = param.split('=');
      query[kv[0]] = kv[1];
    })
    if (query.action) {
      const target = document.querySelector('#' + query.action);
      if (target) {
        target.scrollIntoView(true);
      }
    }
  }, []);

  return (
    <Layout containerSize="lg">
      <SEO title="Home" />

      <Observer
        selector="[data-observe]"
        threshold={.05}
        transition={300}
        viewStyle={{
          opacity: 1,
          transform: 'translate(0,0)',
        }}
        aboveStyle={{
            opacity: 0,
            transform: 'translate(0, -5rem)',
        }}
        belowStyle={{
          opacity: 0,
          transform: 'translate(0, 5rem)',
        }}
      >

        {/* Hide carousel for FW2022 launch
        <Section height="100vh" fixedHeight style={{ padding: `${theme.components.navbar.height}px 0` }}>
          <Hidden smDown>
            <Carousel images={data.bannerImages.nodes} responsive width={300} height={200} />
          </Hidden>
          <Hidden mdUp>
            <Carousel images={data.bannerImagesMobile.nodes} responsive width={300} height={300 / 0.8} />
          </Hidden>
        </Section>
        */}

        <Link to="/shop">
            <div style={{
                maxWidth: '100vw',
                height: '100vh',
                padding: `${theme.components.navbar.height}px 0`,
              }}>
                <img src={data.landingFW2022.publicURL} style={{
                  width: '100%',
                  height: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  objectPosition:"50% 50%"
                }} />
            </div>
        </Link>

        <SectionObserver id="ceramics">
          <Section title="Ceramics" data-observe>
            <SectionLink
              to="/shop"
              label="Shop"
              image={data.ceramicsShop.childImageSharp.fluid}
            />
            <SectionLink
              to="/ceramics"
              label="Works"
              image={data.ceramicsWorks.childImageSharp.fluid}
            />
            <SectionLink
              to="/ceramics/process"
              label="Process"
              image={data.ceramicsProcess.childImageSharp.fluid}
            />
          </Section>
        </SectionObserver>

        <SectionObserver id="photography">
          <Section title="Photography">
            <SectionLink
              to="/photography/yosemite"
              label="Yosemite"
              image={data.photoYosemite.childImageSharp.fluid}
            />
            <SectionLink
              to="/photography/blackandwhite"
              label="Black & White Film"
              image={data.photoBlackAndWhite.childImageSharp.fluid}
            />
            <SectionLink
              to="/photography/iceland"
              label="Iceland"
              image={data.photoIceland.childImageSharp.fluid}
            />
          </Section>
        </SectionObserver>

        <SectionObserver id="design">
          <Section id="design" title="Design" stagger>
            <SectionLink
              to="design/coffee"
              label="Coffee"
              image={data.designCoffee.childImageSharp.fluid}
            />
            <SectionLink
              to="/design/dongzhu-pottery"
              label="Dongzhu Pottery"
              image={data.designDongzhuPottery.childImageSharp.fluid}
            />
            <SectionLink
              to="/design/film-festival"
              label="Film Festival"
              image={data.designFilmFestival.childImageSharp.fluid}
            />
          </Section>
        </SectionObserver>

        <SectionObserver id="about">
          <Section id="about" title="About" underlineTitle compact>
            <Container maxWidth="sm">
              <p>
                Ian Wright is an artist currently based in Los Angeles, California.
                Having been born in LA, Ian carries with him a sense of urban
                aesthetic and cultural curiosity throughout his work.
              </p>
              <p>
                Constantly evolving as an artist, Ian uses many mediums to convey
                his ideas and vision from photography and ceramics to graphic
                design.
              </p>
              <p>
                Currently working on an individual body of work, Ian also works as a
                freelance photographer, creative director, and production
                ceramicist.
              </p>
              <Row align="center" style={{ marginTop: "4rem" }}>
                <IconButton
                  href="https://www.instagram.com/wrrright"
                  icon={["fab", "instagram"]}
                  color="primary"
                />
                <IconButton
                  href="https://www.linkedin.com/in/ian-wright-6b4bb9103"
                  icon={["fab", "linkedin"]}
                  color="primary"
                />
              </Row>
            </Container>
          </Section>
        </SectionObserver>

        <SectionObserver id="subscribe">
          <Section title="Subscribe" compact>
            <Container maxWidth="sm">
              <div style={{ marginTop: '-1rem' }}>
                <Box component="p" color="primary.dark" letterSpacing="-.5px">
                    Join our mailing list to receive updates on new products and promotions.
                </Box>
                <SubscribeForm />
              </div>
            </Container>
          </Section>
        </SectionObserver>

        <SectionObserver id="contact" style={{ marginBottom: '20vh' }}>
          <Section id="contact" title="Contact" compact>
            <Container maxWidth="sm">
              <ContactForm />
            </Container>
          </Section>
        </SectionObserver>

      </Observer>
    </Layout>
  )
}

export const query = graphql`

  fragment extractImageSharp on File {
    relativePath
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  query {
    landingFW2022: file(relativePath: { eq: "home/landing/fw2022.jpg" }) {
      relativePath
      publicURL
    }
    ceramicsShop: file(relativePath: { eq: "home/ceramics-shop-fw2022.jpg" }) { ...extractImageSharp }
    ceramicsWorks: file(relativePath: { eq: "home/ceramics-works.png" }) { ...extractImageSharp }
    ceramicsProcess: file(relativePath: { eq: "home/ceramics-process.png" }) { ...extractImageSharp }
    photoYosemite: file(relativePath: { eq: "photography/yosemite/00a.jpg" }) { ...extractImageSharp }
    photoBlackAndWhite: file(relativePath: { eq: "photography/blackandwhite/001.jpg" }) { ...extractImageSharp }
    photoIceland: file(relativePath: { eq: "photography/iceland/07a.jpg" }) { ...extractImageSharp }
    designCoffee: file(relativePath: { eq: "design/coffee/cover.png" }) { ...extractImageSharp }
    designDongzhuPottery: file(relativePath: { eq: "design/dongzhu_pottery/g01b.jpg" }) { ...extractImageSharp }
    designFilmFestival: file(relativePath: { eq: "design/film_festival/cover.png" }) { ...extractImageSharp }
  }
`;

/**
 * banner images queries
 * query {
    bannerImages: allFile(filter: {relativePath: { glob: "home/banner/*.jpg" }}) {
      nodes {
        relativePath
        publicURL
      }
    }
    bannerImagesMobile: allFile(filter: {relativePath: { glob: "home/banner_mobile/*.jpg" }}) {
      nodes {
        relativePath
        publicURL
      }
    }
 * }
 */

export default IndexPage;
