import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
    root: {
        width: '100%',
        fontSize: 16,
        '& *': {
            fontSize: 'inherit',
        }
    },
    margin: {
        marginTop: theme.spacing(8),
    },
    formActions: {
        marginTop: theme.spacing(10),
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
    },
    formStatus: {
        marginLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}))