import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: props => ({
    height: props.fixedHeight ? props.height : "auto",
    padding: `${theme.spacing(4)}px 0`,
    width: "100%",
    display: "flex",
    flexDirection: props.direction,
    justifyContent: props.justify,
    alignItems: props.align,
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(8)}px 0`,
    },
  }),
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up("md")]: {
      margin: ({ compact }) =>
        compact ? theme.spacing(2, 0) : theme.spacing(0, 0, 16),
    },
  },
  content: {
    height: "auto",
    padding: ({ fixedHeight }) => (fixedHeight ? 0 : theme.spacing(2, 0, 4)),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: ({ fixedHeight }) => (fixedHeight ? 0 : theme.spacing(2, 0, 16)),
      flexDirection: "row",
      "& > *:nth-child(odd)": {
        transform: props =>
          props.stagger ? `translate(0, ${theme.spacing(-6)}px)` : "none",
      },
      "& > *:nth-child(even)": {
        transform: props =>
          props.stagger ? `translate(0, ${theme.spacing(6)}px)` : "none",
      },
    },
  },
}))