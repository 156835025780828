import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import useStyles from "./link.css";

const SectionItem = (props) => {
    
    const classes = useStyles(props);

    return (
        <Link to={props.to} className={classes.root}>
            {props.image && (
                <div className={classes.imageWrapper}>
                    <Img fluid={props.image} />
                    <h5 className={classes.label}>{props.label}</h5>
                </div>
            )}
            {props.label && <h5 className={classes.label}>{props.label}</h5>}
        </Link>
    );
}

export default SectionItem