import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => {

  const transitionShort = theme.transitions.duration.shorter + 'ms';
  const transitionLong = theme.transitions.duration.standard + 'ms';

  return {
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
          '&:not(:first-child)': {
            marginLeft: theme.spacing(6)
          }
        },
  
        '&:hover': {
          "& $label": {
            opacity: 0,
            transform: `translate(0, -100%)`,
            transition: transitionShort,
          },
          "& $imageWrapper:after": {
            opacity: .5,
          },
          "& $imageWrapper $label": {
            opacity: 1,
            transform: `translate(-50%,-100%) scale(1.2)`,
            transition: transitionLong,
          }
        },
    },
    label: {
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: '1.1rem',
      transition: transitionLong,
      margin: `${theme.spacing(2)}px 0`,
    },
    imageWrapper: {
      overflow: 'hidden',
      position: 'relative',
  
      "& $label": {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        color: theme.palette.common.white,
        transform: 'translate(-50%,0) scale(1)',
        zIndex: 5,
        opacity: 0,
        margin: 0,
        transition: transitionShort,
      },
  
      '&, & img, & > *': {
        height: 'auto',
        width: 300,
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
          width: 240,
          maxWidth: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          width: 300,
          maxWidth: '100%',
        },
      },
  
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        opacity: 0,
        zIndex: 2,
        transition: transitionShort,
      },

    },
  }
})