import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import useStyles from './underline.css';

const DividerUnderline = (props) => {

    const classes = useStyles(props);

    return (
        <figure className={classes.root}>
            <Divider className={classes.divider} light={false} />
        </figure>
    )
}

DividerUnderline.propTypes = {
    spacing: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string,
}

export default DividerUnderline;