import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Underline from 'components/divider/underline';
import useStyles from './section.css';

const Section = (props) => {

  const classes = useStyles(props);

  return (
    <section className={classes.root} style={props.style}>
      {props.title && (<>
        <h2 className={classes.title}>
          {props.title}
        </h2>
        {props.underlineTitle && (
          <Box width={200}>
            <Underline color="primary.light" />
          </Box>
        )}
      </>)}
      <div className={classes.content}>
        {props.children}
      </div>
    </section>
  )
}

Section.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
  title: PropTypes.string,
  underlineTitle: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  style: PropTypes.object,
  compact: PropTypes.bool,
  stagger: PropTypes.bool,
}

Section.defaultProps = {
  underlineTitle: false,
  height: '94vh',
  direction: 'column',
  justify: 'center',
  align: 'center',
  fixedHeight: false,
  compact: false,
  stagger: false,
}

export default Section;
