import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        display: 'block',
        textAlign: props => props.align || 'left',

        '& > *:not(:first-child)': {
            marginLeft: props => props.gap || theme.spacing(2),
        }
    }
}))